*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // to make 1rem = 10px => 16px / 10 = 0.625 => 62.5% of current browser default

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    font-size: 50%; //1rem = 8px, 8/16 = 50%
  }

  @include respond(phone) {
    font-size: 55%;
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12px, 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
}
