.homepage {
  padding: 5rem;

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;

    button {
      background-color: $bg-color-secondary;
      border: none;
      margin-top: 2rem;
      color: $bg-color-white;
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.33;
      border-radius: 6px;
      font-weight: 400;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  &__account {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      height: 3rem;
      border-radius: 1rem;
      border: 1px solid #b3b1b1;
      padding: 1rem;

      &::placeholder {
        color: #b3b1b1;
      }
    }
  }

  &__icons {
    cursor: pointer;
  }
}
