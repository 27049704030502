.signin {
  background-color: $bg-color-secondary;
  width: 100%;
  height: 100vh;
  display: flex;

  &__card {
    background-color: $bg-color-white;
    width: 20%;
    height: fit-content;
    margin: auto;
    padding: 2rem;
    border-radius: 3px;

    @include respond(tab-land) {
      width: 30%;
    }
    @include respond(tab-port) {
      width: 35%;
    }
    @include respond(phone) {
      width: 75%;
    }
  }

  &__header {
    color: $text-color-main;
    text-transform: capitalize;
    margin: 2rem 0;
    text-align: center;
    text-transform: capitalize;
    font-size: 4rem;
    font-family: $font-family-secondary;
  }

  &__body {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    input {
      display: block;
      width: 100%;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      position: relative;
      font-size: 16px;
      height: auto;
      padding: 10px;

      &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
    }

    button {
      background-color: $bg-color-secondary;
      border: none;
      margin-top: 2rem;
      color: $bg-color-white;
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.33;
      border-radius: 6px;
      font-weight: 400;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
}
