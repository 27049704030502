// font-families
$font-family-main: "Poppins", sans-serif;
$font-family-secondary: "Dancing Script", cursive;

// font-sizes
$font-size-main: "";

// paddings
$padding-main: "";

// margins
$margin-main: "";

// gaps
$gap-main: "";

// colors
$text-color-main: #212529;
$bg-color-main: #e5e7eb;
$bg-color-secondary: #808080;
$bg-color-tertiary: #f3f4f6;
$bg-color-white: #fff;
